html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

#lightboxBackdrop {
  background-color: rgba(13, 55, 75, 0.7);
}

#lightboxBackdrop > div > div > div:first-child {
  justify-content: flex-end;
  align-items: center;
}

#printJS {
  display: none;
}

.react-confirm-alert-overlay-custom {
  z-index: 2000 !important;
}